import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTheme } from 'src/hooks';
import imgFallback from 'src/utils/image';

function NodeBlogCard({ post, darkMode }) {
  const t = useTheme();

  return (
    <Container fluid className="position-relative px-0">
      <Row noGutters>
        <Col xs={12} className="d-flex">
          <GatsbyImage
            image={post.postImage?.image?.asset?.gatsbyImageData ?? imgFallback}
            alt={post.postImage?.alt ?? ''}
            className="shadow-image"
          />
        </Col>
        <Col xs={12} className="px-3">
          <Row className="py-2">
            <Col xs="auto" className="pr-0">
              <GatsbyImage
                image={post.author?.image?.asset?.gatsbyImageData ?? imgFallback}
                alt="Author"
                style={{
                  width: '65px',
                  height: '65px',
                  marginTop: '-50%',
                  borderRadius: '50%',
                  overflow: 'hidden',
                  border: 'solid white 3px',
                }}
                imgStyle={{ borderRadius: '50%', overflow: 'hidden' }}
              />
            </Col>
            <Col xs="auto" className="text-size-xs text-muted font-weight-bold mt-1">
              {post.publicationDate}
            </Col>
            <Col xs="auto" className="p-0">
              <span className="border-right p-0" />
            </Col>
            <Col xs="auto">
              <div className={`badge-${post.category?.color} text-uppercase px-1`}>{post.category?.name ?? '[category]'}</div>
            </Col>
          </Row>
          <Row className="py-2">
            <Col xs={12} className="mb-1">
              <Link to={`/${t.navigation.blog.path}/${post.slug?.current}`} className="stretched-link text-decoration-none">
                <h2 className={['h4', darkMode ? 'text-white' : 'text-black'].join(' ').trim()}>{post.title ?? '[title]'}</h2>
              </Link>
            </Col>
            <Col xs={12} className="mb-4">
              <p className="text-muted">{post.description}</p>
            </Col>
            <Col xs={12}>
              <Link
                to={`/${t.navigation.blog.path}/${post.slug?.current}`}
                className={[darkMode ? 'btn-link-secondary' : 'btn-link-primary'].join(' ')}
              >
                {t.global.readMore}
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

NodeBlogCard.propTypes = {
  darkMode: PropTypes.bool,
  post: PropTypes.shape({}).isRequired,
};

NodeBlogCard.defaultProps = {
  darkMode: false,
};

export default NodeBlogCard;
